






























































































































































































































































































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import FileInput from "../FileInput.vue";
import SubArrayForm from "@/components/SubArrayForm.vue";
import draggable from "vuedraggable";
import { Project } from "@/data/models/Projects";
import { Question, Questionnaire } from "@/data/models/Questionnaires";
import { Guid } from "guid-typescript";
import { Gender } from "@/data/models/Genders";
import DynamicForm from "@/components/DynamicForm.vue";

@Component({
  components: {
    FormContainer,
    SubArrayForm,
    draggable,
    FileInput,
    DynamicForm
  }
})
export default class QuestionnaireForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<Questionnaire>;

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  @Prop()
  public gendersProvider!: DataProvider<Gender>;

  @Prop()
  public languagesProvider!: DataProvider<Project>;

  @Prop()
  public countriesProvider!: DataProvider<Project>;

  @Prop()
  public crud!: ICrudClient<Questionnaire>;
  private showPreview = false;
  private item: any = null;
  private projects: any[] | null = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        name: "",
        description: "",
        createdAt: new Date().toISOString(),
        questions: [],
        isFinal: false
      };
    }
  }

  @Watch("item")
  onItemChanged() {
    if (this.item && this.item.description === null) {
      this.item.description = "";
    }
  }
  private enums = [
    "AgeRanges",
    "Citizenships",
    "European Countries",
    "Genders",
    "Housing Types",
    "Household Types",
    "Household Member Roles",
    "Languages",
    "Language Skills",
    "Legal Statuses",
    "Locations",
    "Nationalities",
    "Public Authorities",
    "Reach HR360",
    "Vulnerabilities",
    "Requests",
    "Target Types"
  ];
  getNewQuestionId() {
    return Guid.create().toString();
  }
  assignPriority() {
    for (let i = 0; i < this.item.questions.length; i++) {
      this.item.questions[i].priority = i;
    }
  }
  async addEnums(enumName: string, question: Question) {
    if (!question.options) {
      return;
    }
    const items = (
      await this.$service.providers.mapForEnums[
        enumName.replaceAll(" ", "")
      ].fetchItemsAsync()
    ).items;
    for (const item of items) {
      question.options.push({
        id: this.getNewQuestionId(),
        questionId: question.id,
        priority: 0,
        value: item.value
      });
    }
    this.$forceUpdate();
  }
}
